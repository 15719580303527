<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader> <strong>All Users </strong> </CCardHeader>
        <CCardBody>
          <div class="table-responsive">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th scope="col" class="text-left">Username</th>
                  <th scope="col" class="text-left">Name</th>
                  <th scope="col" class="text-left">Email</th>
                  <th scope="col" class="text-left">Contact no.</th>
                  <th scope="col" class="text-left">Role</th>
                  <th
                    scope="col"
                    class="text-center"
                    v-if="
                      can('user_read') ||
                      can('user_update') ||
                      can('user_delete')
                    "
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody class="text-center pt-5" v-if="isLoading">
                <tr>
                  <td class="text-center" colspan="8">
                    <CSpinner color="primary" size="lg" />
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr v-for="user in users" :key="user.id">
                  <td class="text-left">{{ user.username }}</td>
                  <td class="text-left">
                    {{ user.first_name + " " + user.last_name }}
                  </td>
                  <td class="text-left">{{ user.email }}</td>
                  <td class="text-left">{{ user.contact_no }}</td>
                  <td class="text-left">{{ user.role }}</td>

                  <td
                    class="text-center"
                    v-if="
                      can('user_read') ||
                      can('user_update') ||
                      can('user_delete')
                    "
                  >
                    <CButtonGroup size="sm">
                      <CButton
                        color="primary"
                        @click="showUser(showModal, user)"
                        v-if="can('user_read')"
                      >
                        View</CButton
                      >
                      <CButton
                        color="info"
                        @click="editUser(user.id)"
                        v-if="can('user_update')"
                      >
                        Edit</CButton
                      >
                      <CButton
                        @click="deleteUser(deleteModal, user)"
                        color="danger"
                        v-if="can('user_delete')"
                        >Delete</CButton
                      >
                    </CButtonGroup>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </CCardBody>
      </CCard>
    </CCol>
        <UserView />
    <UserDelete />
  </CRow>
</template>

<script>
import { mapState } from "vuex";
const UserView = () => import("@/views/User/UserView");
const UserDelete = () => import("@/views/User/UserDelete");

export default {
  name: "Users",
  components: {
    UserDelete,
    UserView,
  },
  created() {
    if (!this.$store.state.access_token) {
      this.$router.push({ name: "Login" });
    }
    this.$store.dispatch("users/getUsers", {
      Authorization: "Bearer " + this.$store.state.access_token,
    });
  },
  computed: {
    ...mapState({
      users: (state) => state.users.users,
      isLoading: (state) => state.users.isLoading,
      deleteModal: (state) => state.users.deleteModal,
      showModal: (state) => state.users.showModal,
    }),
  },
  methods: {
    can(permisson) {
      return this.$store.state.userPermissions
        ? this.$store.state.userPermissions.indexOf(permisson) >= 0
          ? true
          : false
        : null;
    },
    deleteUser(bool, user) {
      this.$store.commit("users/DELETE_MODAL", { bool: bool, user: user });
    },
    showUser(bool, user) {      
      this.$store.commit("users/SHOW_MODAL", {
        bool: bool,
        user: user,
      });
    },
    editUser(user_id) {
      this.$store.dispatch("users/editUser", {
        user_id: user_id,
      });
      this.$router.push({ name: "Edit User" });
    },
  },
};
</script>
