var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',[_c('CCol',{attrs:{"col":"12","xl":"12"}},[_c('CCard',[_c('CCardHeader',[_c('strong',[_vm._v("All Users ")])]),_c('CCardBody',[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-hover"},[_c('thead',[_c('tr',[_c('th',{staticClass:"text-left",attrs:{"scope":"col"}},[_vm._v("Username")]),_c('th',{staticClass:"text-left",attrs:{"scope":"col"}},[_vm._v("Name")]),_c('th',{staticClass:"text-left",attrs:{"scope":"col"}},[_vm._v("Email")]),_c('th',{staticClass:"text-left",attrs:{"scope":"col"}},[_vm._v("Contact no.")]),_c('th',{staticClass:"text-left",attrs:{"scope":"col"}},[_vm._v("Role")]),(
                    _vm.can('user_read') ||
                    _vm.can('user_update') ||
                    _vm.can('user_delete')
                  )?_c('th',{staticClass:"text-center",attrs:{"scope":"col"}},[_vm._v(" Action ")]):_vm._e()])]),(_vm.isLoading)?_c('tbody',{staticClass:"text-center pt-5"},[_c('tr',[_c('td',{staticClass:"text-center",attrs:{"colspan":"8"}},[_c('CSpinner',{attrs:{"color":"primary","size":"lg"}})],1)])]):_c('tbody',_vm._l((_vm.users),function(user){return _c('tr',{key:user.id},[_c('td',{staticClass:"text-left"},[_vm._v(_vm._s(user.username))]),_c('td',{staticClass:"text-left"},[_vm._v(" "+_vm._s(user.first_name + " " + user.last_name)+" ")]),_c('td',{staticClass:"text-left"},[_vm._v(_vm._s(user.email))]),_c('td',{staticClass:"text-left"},[_vm._v(_vm._s(user.contact_no))]),_c('td',{staticClass:"text-left"},[_vm._v(_vm._s(user.role))]),(
                    _vm.can('user_read') ||
                    _vm.can('user_update') ||
                    _vm.can('user_delete')
                  )?_c('td',{staticClass:"text-center"},[_c('CButtonGroup',{attrs:{"size":"sm"}},[(_vm.can('user_read'))?_c('CButton',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.showUser(_vm.showModal, user)}}},[_vm._v(" View")]):_vm._e(),(_vm.can('user_update'))?_c('CButton',{attrs:{"color":"info"},on:{"click":function($event){return _vm.editUser(user.id)}}},[_vm._v(" Edit")]):_vm._e(),(_vm.can('user_delete'))?_c('CButton',{attrs:{"color":"danger"},on:{"click":function($event){return _vm.deleteUser(_vm.deleteModal, user)}}},[_vm._v("Delete")]):_vm._e()],1)],1):_vm._e()])}),0)])])])],1)],1),_c('UserView'),_c('UserDelete')],1)}
var staticRenderFns = []

export { render, staticRenderFns }